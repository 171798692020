const state = {
  business: null,
  customer: null,
  titleBarColor: '#1E88E5',
  total: 0,
  cart: []
}


const actions = {
  initCustomer({
    commit
  }, payload) {
    commit('INIT_CUSTOMER', payload)
  },
  initBusiness({
    commit
  }, payload) {
    commit('INIT_BUSINESS', payload)
  },
  setTotal({
    commit
}, payload) {
    commit('SET_TOTAL', payload)
  },
  setCart({
    commit
  }, payload) {
    commit('SET_CART', payload)
  }
}

const mutations = {
  SET_CART(state, payload) {
    state.cart = payload
    if (state.cart) {
      let total = 0
      state.cart.map(p => {
        total += p.totalPrice
      })
      state.total = total
    }
  },
  INIT_CUSTOMER(state, payload) {
    state.customer = payload
  },
  INIT_BUSINESS(state, payload) {
    state.business = payload
  },
  SET_TOTAL(state, payload) {
    state.total = payload
  },
}

const getters = {
  getCart(state) {
    return state.cart
  },
  getTotal(state) {
    return state.total
  },
  getCustomer(state) {
    return state.customer
  },
  getBusiness(state) {
    return state.business
  },
  getTitleBarColor(state) {
    return state.titleBarColor
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}