<template>
  <div>
    <v-app-bar app clipped-left dark :color="getTitleBarColor">
      <portal-target name="action-menu-left" multiple></portal-target>
      <span
        class="pl-2 mr-2 font-weight-light"
        v-if="!$vuetify.breakpoint.xsOnly"
        style="color: white; font-size: 25px"
        >smpl <span class="font-weight-black black--text">sale</span>
        <span> | </span>
      </span>

      <!-- <v-app-bar-nav-icon @click.stop="drawerToggle" /> -->

      <v-toolbar-title class="mr-12 align-center" v-if="getBusiness">
        <span class="title white--text text-capitalize">
          {{ getBusiness.name }}
        </span>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <span class="font-weight-bold"
        >Total <span>{{ getTotal | currency }}</span>
      </span>
    </v-app-bar>
    <v-main>
      <router-view> </router-view>
    </v-main>

    <!-- <v-bottom-navigation horizontal app>
      <v-btn to="/remoteOrder">
        <span>Home</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn>
        <span>Submit</span>
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-bottom-navigation> -->
  </div>
</template>
<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import remoteOrderStore from "./store.js";
import RemoteOrderService from "@/modules/Order/RemoteOrderView/service";
import { mapGetters, mapActions } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  name: "remote-order",
  data() {
    return {
      currentBusiness: {
        name: "Business Name will be here",
      },
    };
  },
  computed: {
    ...mapGetters("remoteOrder", [
      "getTitleBarColor",
      "getBusiness",
      "getTotal",
    ]),
  },
  mixins: [RegisterStoreModule],
  created() {
    // // console.log('in index')
    this.getCartAndCustomer();
    this.registerStoreModule("remoteOrder", remoteOrderStore);
  },
  methods: {
    ...mapActions("remoteOrder", ["initCustomer", "initBusiness", "setCart"]),
    getCartAndCustomer() {
      const id = hashids.decode(this.$route.params.id);
      const key = this.$route.query.key;
      // console.log("asdfasdfsadfasdf", parseInt(key));
      if (!key) {
        this.$router.push({ path: "/login" });
      } else {
        // console.log("test key", key);
        RemoteOrderService.getCartAndCustomer(id, { key: key })
          .then((response) => {
            // console.log("getCartAndCustomer", response.data);
            this.initBusiness(response.business);
            this.initCustomer(response.data.customer);
            this.setCart(response.data.remoteOrder.RemoteOrderDetails);
          })
          .catch((error) => {
            // console.log("error here in error", error);
            this.$router.push({ path: "/login" });
          });
      }
    },
  },
};
</script>
